exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-academic-src-pages-404-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/404/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-404-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-contact-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/contact/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-contact-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-experience-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/experience/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-experience-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-posts-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/posts/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-posts-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-research-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/research/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-research-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-resume-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/resume.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-resume-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-tags-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/tags/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-tags-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-wakatime-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/wakatime/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-wakatime-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-hello-index-md": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/home/runner/work/Nagi-ovo.github.io/Nagi-ovo.github.io/content/posts/hello/index.md" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-hello-index-md" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-memo-music-3-0-index-md": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/home/runner/work/Nagi-ovo.github.io/Nagi-ovo.github.io/content/research/memo-music3.0/index.md" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-memo-music-3-0-index-md" */),
  "component---node-modules-gatsby-theme-academic-src-templates-tags-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/tags/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-tags-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-tags-index-jsx-content-file-path-content-tags-gatsby-index-md": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/tags/index.jsx?__contentFilePath=/home/runner/work/Nagi-ovo.github.io/Nagi-ovo.github.io/content/tags/gatsby/index.md" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-tags-index-jsx-content-file-path-content-tags-gatsby-index-md" */)
}

